//@flow
import React, {Ref, useCallback, useRef} from 'react';
import {Form} from "./Form";
import type {LangOption, VindicationPaymentRegistration} from "../api";
import {getLangOptions, useMsgs} from "./Language";
import {store} from "../application";
import {FormikBag, FormikHelpers} from "formik";
import {parseMoney} from "./Utils";

const emptyPayment: VindicationPaymentRegistration = {
    amount: "",
    currency: "PLN",
    date: "",
    description: ""
}

/**
 * Formularz dodawania płatności do windykacji.
 */
const AddInvoicePaymentForm = ({ formikRef, currency, currencies, onSubmit }: {
    formikRef?: Ref<FormikBag>;
    currency?: string;
    /** Dostępne waluty */
    currencies: Array<LangOption>;
    onSubmit: (payment: VindicationPaymentRegistration, helpers: FormikHelpers) => void|Promise<void>;
}) => {
    const msgs=useMsgs();
    return <Form
        initialValues={{ ...emptyPayment, currency: currency || emptyPayment.currency }}
        onSubmit={onSubmit}
        formikRef={formikRef}
        validate={(values: VindicationPaymentRegistration) => {
            let err={};
            if(!values.amount) err.amount=msgs.validation.required;
            else {
                let m=parseMoney(values.amount);
                if(!m) err.amount=msgs.validation.invalidNumber;
                else if(m.isNegative() || m.isZero()) err.amount=msgs.validation.invalidRequiredPositive;
            }
            if(!values.currency) err.currency=msgs.validation.required;
            if(!values.date) err.date=msgs.validation.required;
            return err;
        }}

    >
        <Form.RowGroup name="amount">
            <Form.Label>{msgs.gui.vindicationPaymentAmount}</Form.Label>
            <Form.BigNumber/>
        </Form.RowGroup>
        <Form.RowGroup name="currency">
            <Form.Label>{msgs.gui.vindicationPaymentCurrency}</Form.Label>
            <Form.Select readonly>
                <option></option>
                {getLangOptions(currencies)}
            </Form.Select>
        </Form.RowGroup>
        <Form.RowGroup name="date">
            <Form.Label>{msgs.gui.vindicationPaymentDate}</Form.Label>
            <div className="d-block w-100">
                <Form.Date/>
            </div>
        </Form.RowGroup>
        <Form.RowGroup name="description">
            <Form.Label>{msgs.gui.vindicationPaymentDescription}</Form.Label>
            <Form.TextArea rows={9}/>
            {/*<Form.TextLengthInfo maxLength={4000}/>*/}
        </Form.RowGroup>
    </Form>
}

/**
 * Komponent/okno dodawania płatności do windykacji.
 */
export const AddInvoicePayment = ({ id, currency, formikRef, currencies, onSubmitted }: {
    /** Id windykacji */
    id: string;
    formikRef?: Ref<FormikBag>;
    /** Domyślna waluta */
    currency?: string;
    /** Dostępne waluty */
    currencies: Array<LangOption>;
    onSubmitted?: (done: boolean) => void;
}) => {
    const processingStop=useRef();

    const handleSubmit=useCallback(async(values: VindicationPaymentRegistration, helpers: FormikHelpers) => {
        if(processingStop.current) return;
        processingStop.current=true;
        try {
            if (onSubmitted) await onSubmitted(false);
            const res = await store.userApi.addVindicationPayment(id, values);
        }finally {
            processingStop.current=false;
            if (onSubmitted) await onSubmitted(true);
        }
    }, [ id, onSubmitted ]);

    return <AddInvoicePaymentForm
        currency={currency}
        formikRef={formikRef}
        currencies={currencies}
        onSubmit={handleSubmit}
    />
}
