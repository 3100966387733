//@flow
import React, {useState} from 'react';
import {Button, Form, InputGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import cn from 'classnames';
import {Icon} from "./Components";
import {useMsgs} from "./Language";


const IP_PATTERN=/^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/

export function isIP(ip: string): boolean {
    return IP_PATTERN.test(ip);
}

/**
 * Sprawdza, czy reguła IP jest poprawna.
 * Jest to kopia metody z klasy java IPWall, z tym że
 * nie konwertujemy danych na tablicę bajtów, a jedynie sprawdzamy poprawność.
 * @param rules
 */
export function checkIPRules(rules: string): boolean {
    // Reguły oddzielamy przecinkiem lub średnikiem
    const rulesArray = rules.split(/[,;]/);
    for (let rule of rulesArray) {
        rule=rule.trim();
        if(rule.length===0) continue;
        const rangeSep=rule.indexOf('-');
        if(rangeSep>0) {
            const from = rule.substring(0, rangeSep);
            const to = rule.substring(rangeSep + 1);
            if (!isIP(from) || !isIP(to)) return false;
        } else {
            if (!isIP(rule)) return false;
        }
    }
    return true;
}

function nullifyEmpty(value: string|null): string|null {
    return value===null || value.trim().length===0?null:value;
}

/**
 * Komponent, do wprowadzania reguły IP.
 * Jest on spójny z klasą java IPWall.
 * Jego zadaniem jest walidacja, a więc sprawdzenie, czy wprowadzony tekst jest poprawną regułą IP
 * w kontekście IPWall.
 */
const IPRuleInput = ({ value, className, onSave, maxLength, id }: {
    value: string,
    className?: string,
    onSave: (value: string) => void;
    maxLength?: number;
    id: string;
}) => {
    const msgs = useMsgs();
    const [temp, setTemp] = useState(value || "");
    const [error, setError] = useState(false);
    if (typeof (maxLength) !== 'number') maxLength = 250;

    return <InputGroup className={className}>
        <InputGroup.Prepend>
            <OverlayTrigger
                overlay={<Tooltip id={"ip-rule-tooltip_" + id}>
                    <div dangerouslySetInnerHTML={{__html: msgs.gui.labelIPFilterHint}}/>
                </Tooltip>}
            ><InputGroup.Text>{msgs.gui.labelIPFilter}</InputGroup.Text></OverlayTrigger>
        </InputGroup.Prepend>
        <Form.Control
            type="text"
            value={temp}
            maxLength={maxLength}
            className={cn(error && 'is-invalid')}
            onChange={(e) => {
                setTemp(e.target.value);
                if (checkIPRules(e.target.value)) {
                    setError(false);
                    // onChange(e);
                } else {
                    setError(true);
                }
            }}
        />
        <InputGroup.Append>
            <Button variant="outline-secondary" disabled={!error && nullifyEmpty(value) === nullifyEmpty(temp)}
                    onClick={() => onSave(temp)}>{msgs.gui.actionSave}</Button>
        </InputGroup.Append>
    </InputGroup>
}
export default IPRuleInput;