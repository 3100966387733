//@flow

export type LanguageData = {
	validation: {
		/** Wymiary obrazka {0}x{1} przekraczają dopuszczalny rozmiar {2}x{3} */
		"invalidImageSize": string;
		/** Pole nie może być puste */
		"notNull": string;
		/** Nazwa musi być unikalna */
		"notUniqName": string;
		/** Wybierz minimum jedną opcję */
		"requiredAtLeastOne": string;
		/** Dane niepoprawne */
		"invalidData": string;
		/** Pole jest wymagane */
		"required": string;
		/** Dane niepoprawne. Proszę sprawdzić poprawność danych! */
		"invalidDataLong": string;
		/** Wartość nie może być ujemna */
		"invalidNegativeNumber": string;
		/** Wymagana wartość większa od 0 */
		"invalidRequiredPositive": string;
		/** Pole musi być zaznaczone */
		"assertTrue": string;
		/** Wartość musi być w zakresie {0} do {1} */
		"notInRange": string;
		/** Błędny plik graficzny */
		"invalidImage": string;
		/** Niepoprawna liczba całkowita */
		"invalidInteger": string;
		/** Niepoprawna liczba */
		"invalidNumber": string;
		/** Pole nie może być puste */
		"notBlank": string;
		/** Wartość niezgodna ze wzorcem */
		"invalidPattern": string;
		/** Tablica musi zawierać przynajmniej jeden element */
		"emptyArray": string;
		/** Pole nie może być puste */
		"notEmpty": string;
		/** Błędy adres e-mail */
		"invalidEmail": string;
		/** Niepoprawna wartość */
		"invalidValue": string;
	},
	gui: {
		/** Nowy użytkownik w organizacji */
		"notificationUserAdded": string;
		/** Jest już aktywny inny pakiet. Do czasu jego trwania nie można zmienić typu pakietu. */
		"imSubscriptionNotAvailable": string;
		/** Faktura */
		"documentTypeInvoice": string;
		/** Sukces */
		"imsStatusSuccess": string;
		/** Zleceniodawca */
		"labelCustomer": string;
		/** Oczekujące */
		"statusPending": string;
		/** Przypisz domyślny scenariusz */
		"labelApplyDefaultScenario": string;
		/** Wczytywanie... */
		"dataTableLoading": string;
		/** Ustaw nowe hasło */
		"titleChangePassword": string;
		/** Twój ID dłużnika */
		"labelCustomDebtorId": string;
		/** {0} maili, {1} SMS/VMS w tym miesiącu */
		"imSubscriptionCorporateUsage": string;
		/** Jeden dłużnik */
		"tabOneDebtor": string;
		/** Dalej */
		"buttonNext": string;
		/** Dodaj plik */
		"buttonUpload": string;
		/** Data zgody */
		"labelAgreementDate": string;
		/** dd.MM.yyyy */
		"notificationTitleDate": string;
		/** Abonament za pół roku */
		"imPay6month": string;
		/** Wysyłka wstrzymana */
		"imClientPaused": string;
		/** Złóż ofertę kupna długu */
		"titleDebtBuy": string;
		/** Aby uzyskać klucz <code>API token</code>, należy zalogować się na swoje konto Fakturownia i z menu <b>Ustawienia</b> wybrać pozycję <b>Ustawienia konta</b>. Następnie na ekranie ustawień, należy wybrać pozycję <b>Integracje</b> i w oknie, które się pojawi wybrać pozycję i kliknąć przycisk <b>Zobacz ApiTokeny</b>. Na ekranie z listą kluczy API, należy wybrać przycisk <b>Dodaj nowy Token</b> i wprowadzić nazwę aplikacji w pozycji <b>Opis</b> <code>RozważnaFirma</code> oraz zatwierdzić przyciskiem <b>Zapisz</b>. Po zatwierdzeniu wrócimy na ekran <b>Tokeny API</b> na którym będzie wygenerowany klucz <code>API token</code>, który należy wprowadzić w powyższym formularzu. Dodatkowo do poprawnego działania integracji, należy podać nazwę konta. Nazwa konta to część adresu URL, która jest przed domeną <code>fakturownia.pl</code>. */
		"imIntegrationInfo-Fakturownia": string;
		/** Wybierz dług */
		"titleSelectDebt": string;
		/** W windykacji oczekuje na przyjęcie */
		"monitoringStageVindicationGrace": string;
		/** Koniec abonamentu monitoringu faktur */
		"notificationIMSubscriptionEnded": string;
		/** To hasło nie jest bezpieczne! */
		"insecurePassword": string;
		/** Nazwa scenariusza */
		"labelScenarioName": string;
		/** Opcje */
		"titleOptions": string;
		/** Nazwa klienta */
		"labelClient": string;
		/** Usługi */
		"titleServices": string;
		/** Użytkowniku */
		"nullUsername2": string;
		/** {0} dni temu */
		"agoDays": string;
		/** Nie ma już dostępnych e-mail w pakiecie */
		"notificationIMOutOfEmail": string;
		/** Hasło jest bezpieczne. */
		"passwordGood": string;
		/** Ustawienia integracji */
		"imIntegrationTitle": string;
		/** Kanał wysyłki */
		"labelSendChannel": string;
		/** Status i etap */
		"labelStatusStage": string;
		/** Uwagi do tego długu */
		"labelCommentsToDebt": string;
		/** Błąd danych w pliku: {0} */
		"importInvalid": string;
		/** Nie zlecono jeszcze żadnej usługi */
		"labelNoService": string;
		/** Odrzucona windykacja */
		"imVindicationRejectedFilter": string;
		/** Analiza skuteczności działań */
		"tabInvoiceMonitoringEffectiveness": string;
		/** <p>Hasło zostało zmienione.</p><p>Możesz się już zalogować przy pomocy nowego hasła.</p> */
		"labelResetPasswordChanged": string;
		/** Nieaktywny */
		"imAutoVindicationDisabled": string;
		/** Data wycofania */
		"labelAgreementWithdrawDate": string;
		/** Zrealizowane */
		"statusDone": string;
		/** Powtórz nowe hasło */
		"labelRepeatPassword": string;
		/** Zmień */
		"actionChange": string;
		/** Zamień */
		"actionReplace": string;
		/** Windykacje */
		"titleVindications": string;
		/** Wartość kwot głównych i odsetek */
		"labelTotalDebtExchange": string;
		/** W windykacji - Uwaga! Nie wszystkie faktury zostały zlecone */
		"monitoringClientVindicationWithInvoices": string;
		/** Zleć windykację */
		"imVindicationAccept": string;
		/** SaldeoSmart */
		"istSaldeoSmart": string;
		/** Brak dostępu */
		"labelNoAccess": string;
		/** Rozliczony */
		"monitoringStageSettled": string;
		/** Informacje */
		"labelInformation": string;
		/** Windykacja polubowna */
		"nameVindicationAmicable": string;
		/** niepoprawna nazwa kraju wierzyciela pierwotnego */
		"importInvalidOriginalCreditorCountry": string;
		/** Dodano {0} firmy do obserwowanych klientów */
		"infoMonitoredAddedFew": string;
		/** Anuluj */
		"buttonCancel": string;
		/** Nie można utworzyć windykacji dla tego kontrahenta, bo nie ma faktur, które można windykować. */
		"imNewMissingInvoices": string;
		/** Przekroczono limit monitorowanych */
		"entryOverLimit": string;
		/** Zmiana etapu windykacji */
		"notificationVindicationStage": string;
		/** Zamknij */
		"buttonClose": string;
		/** Ostatnie wpłaty */
		"titleLastPayments": string;
		/** W windykacji */
		"imStatusVindication": string;
		/** ID w RF */
		"labelRFID": string;
		/** <h1>Wkrótce odezwiemy się do Ciebie z dedykowaną ofertą na to zlecenie.</h1><p>Stan swoich zleceń możesz obserwować na stronie zleconych usług.</p> */
		"hintServiceSend": string;
		/** <b>Uwaga!</b><br/>Dla tego kraju dłużnika musimy zweryfikować prowizje. Po dodaniu zlecenia skontaktujemy się z Tobą. Nie zostaniesz obciążony żadnymi opłatami do czasu akceptacji oferty. */
		"hintNewDebtOtherPrices": string;
		/** Wyłącz z monitorowanych */
		"imInvoicePause": string;
		/** Nazwa użytkownika */
		"imKey-iFirma": string;
		/** Wkrótce odezwiemy się do Ciebie. */
		"hintDebtBought": string;
		/** Ostatnio wysłane i planowane przypomnienia */
		"imLastSent": string;
		/** Przyjęcie zgłoszenia windykacji */
		"notificationVindicationAccepted": string;
		/** Błąd serwera */
		"networkErrorInternalError": string;
		/** Obiekt zlecenia */
		"labelOrderTarget": string;
		/** Kopiuj */
		"actionCopy": string;
		/** Scenariusz monitoringu */
		"tabMonitoringScenario": string;
		/** Inne koszty */
		"labelOtherCosts": string;
		/** https://rozwaznafirma.pl */
		"authLink": string;
		/** API key */
		"imKey-inFakt": string;
		/** Data wn. egz. */
		"labelExecutiveApplicationDate": string;
		/** Łączna kwota zleceń */
		"labelDebtorVindicationsSum": string;
		/** Użytkownik {0} złożył ofertę kupna długu {1} */
		"notificationDebtExchangeBuyMessage": string;
		/** <h1>Żadna z obserwowanych firm nie posiada długu w systemie Rozważna Firma.</h1>Wyświetlamy wszystkie obserwowane przez Ciebie firmy. */
		"hintNoMonitoredWithDebts": string;
		/** Maxi */
		"imSubscriptionMaxi": string;
		/** Dane zostały usunięte. */
		"deleteAllInfo": string;
		/** Pokaż więcej */
		"actionShowMore": string;
		/** Dodaj klienta */
		"actionAddClient": string;
		/** Ustawienia */
		"titleSettings": string;
		/** Utwórz klucz API */
		"actionCreateApiKey": string;
		/** Brak numeru telefonu */
		"imMissingPhone": string;
		/** Do */
		"labelTo": string;
		/** Potwierdź */
		"buttonConfirm": string;
		/** Aktywny */
		"statusActive": string;
		/** Dodaj pliki ({0}) */
		"titleAddFileLang": string;
		/** Ilość długów */
		"labelDebtsCount": string;
		/** Wyślij */
		"actionSend": string;
		/** Abonament za cały rok */
		"imPay12month": string;
		/** Przetwarzanie */
		"imsStatusProcessing": string;
		/** Termin płatności faktury {0} upłynął */
		"notificationIMInvoiceOverdue": string;
		/** Język */
		"labelLanguage": string;
		/** po terminie płatności */
		"imLabelAfterDateOfPayment": string;
		/** Termin płatności */
		"labelDueTo": string;
		/** Użytkownik jest już dodany do organizacji */
		"errorUserAlreadyAdded": string;
		/** Numer faktury */
		"labelInvoiceNumber": string;
		/** Giełda długów */
		"titleDebtMarket": string;
		/** Klucz API modułu faktura */
		"imKey2-iFirma": string;
		/** Brak połączenia z serwerem */
		"networkErrorNoConnection": string;
		/** Filtr ograniczający */
		"accessFilter": string;
		/** Brak przypisanego scenariusza */
		"monitoringInvoiceNoScenario": string;
		/** Nowy */
		"statusNew": string;
		/** Wygeneruj nowy */
		"actionRegenerateApiKey": string;
		/** Raport windykacyjny */
		"vReportTitle": string;
		/** <h1>Zlecenia możesz dodać z pliku CSV lub XLSX o odpowiedniej strukturze.</h1><p>Aby to zrobić pobierz, a następnie uzupełnij szablon pliku <a href="/resources/templates/debtcollections_ir.xlsx" target="_blank">debtcollections_ir.xlsx</a>.</p> */
		"hintNewDebtsUploadIR": string;
		/** Brak adresu e-mail */
		"imMissingEmail": string;
		/**  */
		"imEffectivenessNoDataHint": string;
		/** Historia abonamentu */
		"menuInvoiceMonitoringSubscriptionHistory": string;
		/** {0} maili, {1} SMS/VMS */
		"labelNotificationsSendValue": string;
		/** Kontrahent {0} przekroczył 100% stanu monitoringu i został przekazany do windykacji. Zostało utworzone {2} windykacji. Masz {1} dni na anulowanie zleceń. */
		"notificationIMClientVindicationGraceMultiple": string;
		/** Cenniki tej firmy */
		"titleCompanyPrices": string;
		/** Aktywni */
		"labelUsersActive": string;
		/** Domyślny scenariusz */
		"labelDefaultScenario": string;
		/** Kwota */
		"labelAmount": string;
		/** Użytkownicy z dostępem do tej firmy */
		"titleCompanyUsers": string;
		/** Usuń */
		"actionDelete": string;
		/** Nazwa konta */
		"imKey2-Fakturownia": string;
		/** Opłacona */
		"imStatusPaid": string;
		/** Hasła się różnią. */
		"validatePasswordDifferent": string;
		/** Stan */
		"labelState": string;
		/** Nie zgłoszono jeszcze niczego do windykacji */
		"titleNoVindications": string;
		/** Wgraj plik Worda lub Excela z zestawieniem należności Twojego dłużnika, a nasi specjaliści wypełnią zlecenie windykacji za Ciebie! */
		"hintUploadDebts": string;
		/** Secret key */
		"imKey2-wFirma": string;
		/** E-mail */
		"labelStepEmail": string;
		/** Nie znaleziono strony: {0} */
		"error404": string;
		/** English */
		"langChangeLang": string;
		/** rzeczowy */
		"issSlightlyOutOfDate": string;
		/** Wgraj zestawienie należności w pliku */
		"vindicationReceivablesFromFile": string;
		/** Zarejestruj się! */
		"actionRegister": string;
		/** Kapitał */
		"labelSummaryCapital": string;
		/** Wybierz... */
		"comboboxSelect": string;
		/** Utwórz windykację */
		"imNewVindicationAction": string;
		/** Data przyjęcia: {0} */
		"labelNewDebtDate": string;
		/** Domyślny scenariusz */
		"imDefaultScenarioSettings": string;
		/** Import ręczny */
		"imhManualImport": string;
		/** lub przeciągnij i upuść w tym miejscu */
		"hintUploadChange": string;
		/** Automatyczne przekazywanie zleceń do windykacji, po zakończeniu scenariusza. */
		"imAutoVindication": string;
		/** Nie zgłoszono jeszcze niczego do windykacji */
		"labelNoVindications": string;
		/** Koszty */
		"labelSummaryCosts": string;
		/** Wycofaj */
		"actionAgreementWithdraw": string;
		/** Dodanie wpisu do listy monitorowanych */
		"notificationMonitoredAdded": string;
		/** Faktura zagrożona */
		"monitoringInvoiceStageExceedingScenario": string;
		/** Przed terminem */
		"monitoringStageBeforePayment": string;
		/** Zafakturowana */
		"labelInvoiced": string;
		/** Automatycznie kieruj do */
		"labelAutoVindicationMode": string;
		/** Szukaj w opisie zdarzenia */
		"hintActionsSearch": string;
		/** Moje konto */
		"labelMyAccount": string;
		/** Dodatkowe dokumenty związane ze sprawą */
		"labelAdditionalDocuments": string;
		/** Nowy plik z trudnymi długami */
		"notificationDifficultDebtNew": string;
		/** Zablokowany */
		"statusBlocked": string;
		/** Adres e-mail */
		"labelEmail": string;
		/** {0}, sprawdź swoją pocztę */
		"titleResetMessageSend": string;
		/** Faktura opłacona */
		"imMarkAsPayed": string;
		/** Faktury dla nowej windykacji */
		"imNewVindicationInvoices": string;
		/** Zleć */
		"actionAddServicePrefix": string;
		/** Ograniczony */
		"rightsSome": string;
		/** Free */
		"imSubscriptionFree": string;
		/** Błąd wywołania metody:  */
		"networkErrorMethod": string;
		/** Koszty prowadzenia sprawy */
		"titleVindicationCosts": string;
		/** Pokaż tylko z publikacjami z iMSiG */
		"labelShowOnlyImsig": string;
		/** Token zdalnej pomocy */
		"remoteHelpToken": string;
		/** Zbliża się koniec abonamentu faktur */
		"notificationIMSubscriptionNearEnd": string;
		/** <h1>Możesz dodać jeszcze {0} firm.</h1><p>Skontaktuj się z nami, aby zwiększyć ilość obserwowanych.</p> */
		"hintAddInfo": string;
		/** {0}: Akceptacja zlecenia */
		"notificationOrderAccepted": string;
		/** Od godziny */
		"labelNotificationFrom": string;
		/** Aby uzyskać <code>token</code> dostępu należy skontaktować się z biurem rachunkowym obsługującym Państwa. */
		"imIntegrationInfo-SaldeoSmart": string;
		/** <h1>Faktury możesz dodać z pliku CSV lub XLSX o odpowiedniej strukturze.</h1><p>Aby to zrobić pobierz, a następnie uzupełnij szablon pliku <a href="/resources/templates/faktury.xlsx" target="_blank">faktury.xlsx</a>.</p> */
		"hintImportInvoices": string;
		/** Odrzucona */
		"orgStatusRejected": string;
		/** Netto: */
		"imPayNet": string;
		/** Token dostępu */
		"imKey-SaldeoSmart": string;
		/** Etap */
		"labelStage": string;
		/** Jeżeli Twój dokument nie posiada numeru, proszę wypełnij to pole opisem należności np.: "odsetki za 06-06-2020 do 12-12-2020". */
		"hintNoDocumentNr": string;
		/** 250 maili + 150 sms/vms */
		"imPacketSMSMailDetails": string;
		/** Użytkownik {0} zmodyfikował użytkownika {1} */
		"notificationUserChangedMessage": string;
		/** Zlecenie {0} zostało zakończone */
		"notificationOrderDoneMessage": string;
		/** Opis */
		"vindicationPaymentDescription": string;
		/** Windykacja polubowna */
		"debtTypeAmicable": string;
		/** OK */
		"buttonOK": string;
		/** Nie wykonano synchronizacji */
		"imLabelNeverSync": string;
		/** Firma */
		"labelCompany": string;
		/** Hasło jest mało bezpieczne! */
		"passwordWeak": string;
		/** Aktualny kapitał */
		"labelActualCapital": string;
		/** Przyjęcie zgłoszenia windykacji {0} */
		"notificationVindicationAcceptedMessage": string;
		/** Data wpłaty */
		"vindicationPaymentDate": string;
		/** Suma */
		"labelSum": string;
		/** (wygenerowany dnia: {0}) */
		"reportGenerated": string;
		/** Nie obserwujesz jeszcze żadnego klienta */
		"labelNoMonitored": string;
		/** Nowa windykacja */
		"imNewVindication": string;
		/** Nie wybrano pakietu dla organizacji. */
		"hintOrganizationNoPacket": string;
		/** Brak wpłat */
		"labelNoPayments": string;
		/** Brak dostępu do funkcji wersji demonstracyjnej. Skontaktuj się z administratorem, aby uzyskać dostęp do firmy demo. */
		"labelNoDemo": string;
		/** Kroki scenariusza */
		"imScenarioSteps": string;
		/** ID zlecenia */
		"labelOrderId": string;
		/** Firma została zatwierdzona */
		"notificationOrganizationAcceptedMessage": string;
		/** Kontrahent {0} przekroczył 100% stanu monitoringu */
		"notificationIMClientExceedingScenario": string;
		/** {0} dni */
		"labelActivityDays": string;
		/** Dodaj wpłatę */
		"vindicationAddPayment": string;
		/** Dodaj plik lub zdjęcie */
		"actionAddFileOrPhoto": string;
		/** Włącz klienta do monitoringu */
		"imClientEnable": string;
		/** Zablokuj tymczasowo */
		"actionTempBlock": string;
		/** Razem */
		"labelTotal": string;
		/** Hasło jest bardzo bezpieczne. */
		"passwordVeryGood": string;
		/** Data zmiany */
		"labelChangeTime": string;
		/** Wpisz kod promocyjny */
		"registerPromoHint": string;
		/** Nazwa klucza */
		"labelKeyName": string;
		/** Ostatnie powiadomienia w tej firmie */
		"titleCompanyNotifications": string;
		/** Manualny import */
		"imManualImport": string;
		/** Aktualnie monitorowane */
		"imLabelCurrentlyMonitored": string;
		/** ...Wybierz... */
		"optionSelect": string;
		/** Do negocjacji */
		"labelDebtExchangePriceNegotiate": string;
		/** Niepoprawny adres e-mail. */
		"resetInvalidEmail": string;
		/** NIP */
		"labelNIP": string;
		/** Podsumowanie */
		"tabSummary": string;
		/** Nr dokumentu */
		"labelDocumentNr": string;
		/** Miejscowość */
		"labelCity": string;
		/** Użytkownik */
		"nullUsername": string;
		/** Sukces */
		"issSuccess": string;
		/** Kontakt */
		"linkContact": string;
		/** Środki wykorzystane */
		"labelNotificationsSend": string;
		/** Zlecenie windykacji polubownej, sądowa */
		"vReportAmicableJudicial": string;
		/** {0} - {1} */
		"labelDateRange": string;
		/** Zakres dat */
		"labelDateRangeInfo": string;
		/** Numer telefonu stacjonarnego */
		"imLandLinePhoneNumber": string;
		/** Zdalna pomoc */
		"titleRemoteHelp": string;
		/** Synchronizacja odbywa się automatycznie o godzinach: 7, 11, 15 oraz 19. Możliwe jest wywołanie synchronizacji ręcznie z zakładki historii importu. */
		"imIntegrationSyncInfo": string;
		/** Organizacje */
		"labelOrganizations": string;
		/** Access key */
		"imKey-wFirma": string;
		/** Nazwa poziomu dostępu */
		"labelRoleName": string;
		/** Ustawienia */
		"tabSettings": string;
		/** Twój bieżący abonament */
		"labelCurrentSubscription": string;
		/** Email */
		"channelEmail": string;
		/** Filtr IP */
		"labelIPFilter": string;
		/** faktur */
		"imLabelInvoices": string;
		/** {0} ({1} ekstra) e-maili, {2} ({3} ekstra) SMS/VMS */
		"imSubscriptionAvailableValue": string;
		/** Treść wiadomości */
		"labelMessageContent": string;
		/** Zlecone */
		"statusOrdered": string;
		/** Zmiana etapu na {1} windykacji {0} */
		"notificationVindicationStageMessage": string;
		/** Oczekujący */
		"labelUsersToAccept": string;
		/** 15 maili, 0 SMS/VMS */
		"imSubscriptionFreeCount": string;
		/** Dzień */
		"labelMonitoringDay": string;
		/** Brak wpisów */
		"labelImsigEmpty": string;
		/** netto za pakiet */
		"labelPricePacket": string;
		/** Przypisz aktualny scenariusz do kontrahentów bez scenariusza */
		"actionApplySettingsBlank": string;
		/** Wybierz czy chcesz utworzyć nową egzekucję, czy chcesz dodać dług do istniejącej. */
		"hintSelectDebtInfo": string;
		/** Wznów wysyłkę powiadomień */
		"imClientRestore": string;
		/** Data dodania */
		"labelTimeAdded": string;
		/** brak waluty lub niepoprawna wartość waluty */
		"importInvalidCurrency": string;
		/** Obciąż dłużnika */
		"labelChargeDebtor": string;
		/** Pliku '{0}' nie udało się przesłać, ponieważ jest niewłaściwego typu. */
		"uploadTypeError": string;
		/** Klienci */
		"menuInvoiceMonitoringClients": string;
		/** Aktywność */
		"labelActivity": string;
		/** Sprawdź poprawność danych */
		"titleCheckUploadedData": string;
		/** Powiadomienia */
		"labelNotifications": string;
		/** Integracja z DataIntegrator */
		"imIntegrationDataIntegrator": string;
		/** Aktywna */
		"orgStatusActive": string;
		/** Kraj */
		"labelCountry": string;
		/** Telefon */
		"labelPhone": string;
		/** <p>Dbamy o Twoje bezpieczeństwo dlatego prosimy, otwórz mail od nas i kliknij w link aktywujący Twoje darmowe konto.</p><p>Jeśli w ciągu kilku minut e-mail nie dotrze, sprawdź folder Spam lub kliknij poniżej by wysłać go ponownie.</p> */
		"hintRegistrationComplete": string;
		/** {0} z 25 maili, {1} z 25 SMS/VMS */
		"imSubscriptionMiniUsage": string;
		/** Użytkownicy */
		"tabUsers": string;
		/** Zgody */
		"tabAgreements": string;
		/** Pozostały jeszcze {0} pozycje */
		"dataTableDataCount": string;
		/** Nazwa wierzyciela pierwotnego */
		"labelCreditorName": string;
		/** Standardowy */
		"labelDefaultPrices": string;
		/** Jeśli chcesz, możesz dodać dokumenty i pliki związane i pomoce w windykacji Twoich należności */
		"hintAdditionalDocuments": string;
		/** Integracja z DataIntegrator działa na podstawie klucza, który jest generowany po stronie systemu RozważnaFirma i który należy wprowadzić w ustawieniach aplikacji DataIntegrator. Po zapisaniu zmian w tym formularzu zostanie wygenerowany nowy klucz API. */
		"imIntegrationInfo-DataIntegrator": string;
		/** Zablokowani */
		"labelUsersBlocked": string;
		/** miesięcznie */
		"imSubscriptionMonthly": string;
		/** Odrzuć */
		"actionReject": string;
		/** ...Wybierz domyślny scenariusz... */
		"selectScenario": string;
		/** Nie zmieniaj ustawień scenariuszy dla istniejących kontrahentów */
		"actionApplySettingsNone": string;
		/** Moje konto */
		"titleMyAccount": string;
		/** Cenniki */
		"labelPrices": string;
		/** Inne windykacje tego zlecającego na tego dłużnika */
		"titleOtherClientDebts": string;
		/** W windykacji */
		"monitoringInvoiceStageVindication": string;
		/** Wiadomości e-mail oraz SMS/VMS niewykorzystane w danym miesiącu nie przechodzą na kolejny miesiąc. */
		"labelSubscriptionInfo": string;
		/** Nazwij ten plik */
		"labelNameThisFile": string;
		/** Brak ustawień synchronizacji */
		"imNoIntegration": string;
		/** Dla danego adresu e-mail istnieje już konto */
		"registerAccountExists": string;
		/** {0}, aby ułatwić Ci korzystanie z Rozważnej Firmy, musimy jeszcze poprosić Cię o uzupełnienie danych firmy. */
		"hintCompanyData": string;
		/** Przyjęliśmy zgłoszenie! */
		"titleDebtBought": string;
		/** Zapisz jako szablon */
		"actionSaveSearch": string;
		/** W celu potwierdzenia operacji przepisz treść z poniższego obrazka */
		"captchaMessage": string;
		/** brak kodu pocztowego dłużnika */
		"importInvalidPostalCode": string;
		/** brak miejscowości dłużnika */
		"importInvalidCity": string;
		/** polubowna */
		"labelAmicable": string;
		/** Usługa */
		"labelServiceType": string;
		/** <h1>Żadna z obserwowanych firm nie posiada wpisów w systemie iMSiG.</h1>Wyświetlamy wszystkie obserwowane przez Ciebie firmy. */
		"hintNoMonitoredWithImsig": string;
		/** Poziom dostępu */
		"labelRole": string;
		/** Operacja została poprawnie wykonana */
		"infoExecutedSuccessful": string;
		/** dni */
		"labelUnitDays": string;
		/** Użytkownicy */
		"labelUsers": string;
		/** Brak przypisanego scenariusza */
		"imNoScenario": string;
		/** Nie potwierdzone */
		"statusNotAccepted": string;
		/** Dla podanego numeru NIP jest już zarejestrowana organizacja. */
		"addCompanyExists": string;
		/** SaldeoSmart */
		"imIntegration-SaldeoSmart": string;
		/** Utworzenie nowego jest operacją nieodwracalną. Stary klucz API zostanie nadpisany i systemy, które z niego korzystają, utracą dostęp do systemu. Wymagana będzie aktualizacja klucza API w systemie do poprawnego działania.  */
		"apiKeyRegenerateInfo": string;
		/** Nazwa szablonu */
		"labelTemplateName": string;
		/** brak nazwy dłużnika */
		"importInvalidDebtorName": string;
		/** Wiadomość wysłana. */
		"hintMessageSend": string;
		/** Wstecz */
		"buttonBack": string;
		/** Dłużnik */
		"titleDebtor": string;
		/** Ryzyko */
		"labelDebtRisk": string;
		/** Bez zobowiązań */
		"registerHint2": string;
		/** brakuje wartości należności lub niepoprawna wartość */
		"importInvalidAmount": string;
		/** Za darmo */
		"registerHint1": string;
		/** Przeterminowana */
		"monitoringInvoiceStageExpired": string;
		/** Długość monitoringu */
		"labelScenarioDays": string;
		/** Podaj należności samodzielnie */
		"labelEntryDebts": string;
		/** Brak dostępu skontaktuj się z BOK */
		"labelImsigNoAccess": string;
		/** Zlecenie {0} zostało zaakceptowane */
		"notificationOrderAcceptedMessage": string;
		/** Nazwij ten plik */
		"hintCustomFileName": string;
		/** W tym miejscu pojawią się informacje o zmianach w Twoich windykacjach. */
		"hintNoEvents": string;
		/** Nie znaleziono wymaganego zasobu */
		"networkErrorNotFound": string;
		/** Zgłoszenia windykacji {0} zostało odrzucone: {1} */
		"notificationVindicationRejectedMessage": string;
		/** różne ustawienia ubezpieczyciela dla jednego długu */
		"importInvalidDifferentInsurer": string;
		/** Kapitał */
		"labelDebtCapital": string;
		/** Dane kontaktowe */
		"titleContact": string;
		/** Lp. */
		"labelRowNumber": string;
		/** Adres dłużnika */
		"labelDebtorAddress": string;
		/** E-mail */
		"labelEmailOnly": string;
		/** Niepowodzenie */
		"imsStatusFailed": string;
		/** Powiadomienie o nowym wpisie z Monitora Sądowego */
		"notificationMonitoredImsig": string;
		/** Plany abonamentowe */
		"labelSubscriptions": string;
		/** Fakturownia */
		"imIntegration-Fakturownia": string;
		/** Nowa wierzytelność dla windykacji {0} */
		"notificationVindicationReceivableMessage": string;
		/** Obiekt usługi */
		"labelServiceTargetName": string;
		/** Obserwuj klienta */
		"actionAddMonitored": string;
		/** {0}: Odrzucenie zlecenia */
		"notificationOrderRejected": string;
		/** Rodzaj cennika */
		"labelPriceType": string;
		/** Edytuj kroki */
		"buttonEditSteps": string;
		/** Przeterminowane */
		"monitoringStageExpired": string;
		/** brak adresu dłużnika */
		"importInvalidAddress": string;
		/** W podsumowaniu */
		"labelNotificationsInSummary": string;
		/** Odzyskano łącznie */
		"labelDebtorRecoveredSum": string;
		/** 1 dzień temu */
		"agoDay": string;
		/** Wpłaty */
		"titlePayments": string;
		/** Śr. opóźnienie płatności */
		"imLabelAverageOverdue": string;
		/** Brak */
		"labelItemCountNone": string;
		/** 25 maili, 25 SMS/VMS */
		"imSubscriptionMiniCount": string;
		/** wierzytelność ubezpieczona, ale brak jest ubezpieczyciela */
		"importInvalidInsurer": string;
		/** Wgraj */
		"actionUpload": string;
		/** Wyloguj */
		"actionLogout": string;
		/** Pakiet SMS */
		"imPacketSMS": string;
		/**  */
		"labelActions": string;
		/** Pobieranie danych... */
		"tableLoading": string;
		/** System Zarządzania Należnościami Rozważna Firma należy do EULEO spółka z ograniczoną odpowiedzialnością sp. k */
		"labelFooterCompanyInfo": string;
		/** Wierzyciel */
		"labelCreditor": string;
		/** Odsetki */
		"labelSummaryInterests": string;
		/** Sądowych */
		"labelDebtorJudicial": string;
		/** Podaj należności samodzielnie */
		"vindicationReceivablesFromDocs": string;
		/** Zwolnij blokadę */
		"actionRemoveBlock": string;
		/** brak numeru NIP */
		"importInvalidTax": string;
		/** sądowa */
		"labelJudicial": string;
		/** sądowa i polubowna */
		"labelAmicableJudicial": string;
		/** dni */
		"labelDays": string;
		/** Przez e-mail */
		"labelNotificationsByEmail": string;
		/** Pozostało */
		"labelLeft": string;
		/** Usuń wszystkie faktury */
		"actionDeleteInvoices": string;
		/** Niepoprawny numer telefonu */
		"imInvalidPhone": string;
		/** Od {0} */
		"labelDateRangeFrom": string;
		/** Imię i nazwisko */
		"labelFullName": string;
		/** Dodaj NIP klienta by otrzymywać informacje o ewentualnym pojawieniu się tej firmy na liście dłużników. */
		"hintNoMonitored": string;
		/** Faktury */
		"tabInvoices": string;
		/** Zlecenie windykacji sądowej */
		"vReportJudicial": string;
		/** Zleć windykację */
		"actionOrderDebtCollection": string;
		/** W windykacji oczekuje na przyjęcie */
		"monitoringInvoiceStageVindicationGrace": string;
		/** Upadłości i inne ogłoszenia z Monitora Sądowego */
		"labelImsig": string;
		/** Windykacja sądowa */
		"debtTypeJudicial": string;
		/** Hasło */
		"labelPassword": string;
		/** Komunikat błędu z serwera:  */
		"networkErrorServerMessage": string;
		/** Powiadomienie o wykryciu długu */
		"notificationMonitoredDebt": string;
		/** Podgląd */
		"accessRead": string;
		/** Dodatkowy opis */
		"labelServiceDesc": string;
		/** Moje szablony wyszukiwania */
		"labelSearchTemplate": string;
		/** Historia importu */
		"menuInvoiceMonitoringSyncHistory": string;
		/** Ostatnie logowanie */
		"labelLastLogin": string;
		/** Cennik */
		"labelPricesFile": string;
		/** Nowy obrazek */
		"buttonCaptchaRenew": string;
		/** Resetuj hasło */
		"actionResetPassword": string;
		/** Uprawnienia organizacji */
		"titleCompanyRights": string;
		/** Nowe zdarzenie w windykacji {0} */
		"notificationVindicationActionMessage": string;
		/** Usuń ustawienia scenariuszy */
		"actionDeleteContractorsScenarios": string;
		/** Przejdź do strony logowania */
		"labelGotoLogin": string;
		/** Użytkownik {0} utworzył nowe zlecenie {1} */
		"notificationOrderNewMessage": string;
		/** Data sprzedaży */
		"labelSellDate": string;
		/** Regulamin */
		"linkTerms": string;
		/** Przejdź do historii abonamentu */
		"paymentStatusGotoHistory": string;
		/** Płatność zakończona niepowodzeniem. Spróbuj ponownie. */
		"paymentStatusFailed": string;
		/** Skontaktuj się */
		"imSubscriptionCorporateContact": string;
		/** 100 maili, 100 SMS/VMS */
		"imSubscriptionMidiCount": string;
		/** Ustawienie nowego pakietu */
		"imsEventAdminSubscription": string;
		/** Brak dostępu */
		"titleNoAccess": string;
		/** Moje dane */
		"titleMyData": string;
		/** Aktywuj */
		"actionActivate": string;
		/** wysokie ryzyko */
		"tagHighRisk": string;
		/** Dłużnik i Twój ID */
		"labelDebtorWithId": string;
		/** Wartość początkowa */
		"labelInitialValue": string;
		/** Edytuj organizację */
		"titleEditOrganization": string;
		/** Sygn. sądowa */
		"labelCourtRefNumber": string;
		/** Edytuj */
		"actionEdit": string;
		/** netto za miesiąc */
		"labelPriceMonth": string;
		/** Użytkownicy do akceptacji */
		"titleUsersToAccept": string;
		/** Zablokuj */
		"actionBlock": string;
		/** dzień */
		"labelDay": string;
		/** Przesłana */
		"infoPaymentSend": string;
		/** Pakiety SMS/VMS oraz e-mail */
		"labelInvoiceMonitoringPackets": string;
		/** Data wysłania */
		"labelSendDate": string;
		/** Ważny do: {0} */
		"imSubscriptionTerm": string;
		/** Nowe zdarzenie w windykacji */
		"notificationVindicationAction": string;
		/** Odrzucenie zgłoszenia windykacji */
		"notificationVindicationRejected": string;
		/** Pełny */
		"rightsFull": string;
		/** Kontrahent {0} przekroczył 100% stanu monitoringu i został przekazany do windykacji */
		"notificationIMClientVindication": string;
		/** Osiągnąłeś limit obserwowanych {0} kontrahentów */
		"hintLimitReached": string;
		/** Podsumowanie należności */
		"vReportDebtSummary": string;
		/** Windykacja polubowna */
		"labelAutoVindicationAmicable": string;
		/** Dług na sprzedaż */
		"titleDebtDetails": string;
		/** Do {0} */
		"labelDateRangeTo": string;
		/** Nie wybrano domyślnego scenariusza monitoringu. Monitoring faktur nieaktywny. */
		"noScenarioHint": string;
		/** Waluta wpłaty */
		"vindicationPaymentCurrency": string;
		/** Konto zostało aktywowane. */
		"registerAccountActivated": string;
		/** Edytuj użytkownika */
		"titleEditUser": string;
		/** Odzyskano */
		"labelDebtRecovered": string;
		/** Szukaj (typ zdarzenia, treść, nazwa lub ID windykacji) */
		"labelSearch": string;
		/** Adres e-mail do odpowiedzi na powiadomienia z systemu */
		"labelNotificationEmail": string;
		/** Ustawienia... */
		"labelScenarioSettings": string;
		/** Wyłącz klienta z monitoringu */
		"imClientDisable": string;
		/** Etap realizowany w momencie, gdy upłynął termin płatności dla najstarszej faktury w saldzie. Nadawcą komunikatu będzie Twoja firma, a treść komunikatów utrzymana będzie w delikatnym, przypominającym tonie. */
		"imStageSlightlyOutOfDateInfo": string;
		/** {0} z 15 maili */
		"imSubscriptionFreeUsage": string;
		/** Mini */
		"imSubscriptionMini": string;
		/** Nazwa organizacji */
		"labelOrganizationName": string;
		/** Konto zablokowane! */
		"accountBlocked": string;
		/** Data */
		"labelDate": string;
		/** Od */
		"labelFrom": string;
		/** Data publikacji */
		"labelPublished": string;
		/** brak nazwy kontrahenta */
		"importInvalidContractor": string;
		/** Podaj NIP */
		"hintEnterTax": string;
		/** Oszczędzasz 10% */
		"imSubscription6MonthInfo": string;
		/** Liczba należności */
		"labelDebts": string;
		/** Typ */
		"labelType": string;
		/** Na tym etapie od */
		"labelThisStageFrom": string;
		/** Usunięcie danych */
		"istReset": string;
		/** Temat */
		"labelTopic": string;
		/** Pliku '{0}' nie udało się przesłać. */
		"uploadOtherError": string;
		/** {0} dzień */
		"labelActivityDay": string;
		/** Dokupione wiadomości e-mail oraz SMS/VMS nigdy nie przepadają. Jeśli nie wykorzystasz ich w danym miesiącu, w kolejnym będą wykorzystane dopiero po zużyciu wszystkich wiadomości otrzymanych w abonamencie. */
		"labelInvoiceMonitoringPacketsInfo2": string;
		/** Automatycznie dodawaj nowych klientów do obserwowanych */
		"labelAutoObserve": string;
		/** {0}, windykacja {1} z dnia: {2}, aktualne saldo: {3} */
		"hintAddToDebt": string;
		/** W windykacji */
		"monitoringInvoiceVindication": string;
		/** {0} w toku {1} ogółem */
		"labelItemCount": string;
		/** ID zlecenia / Typ zlecenia */
		"labelOrderIdType": string;
		/** Odsetki */
		"labelDebtInterests": string;
		/** Kup abonament */
		"imSubscriptionBuy": string;
		/** Zapisz tne filtr jako szablon wyszukiwania */
		"titleFilterSave": string;
		/** Wprowadziliśmy kilka zmian */
		"titleTerms": string;
		/** API token */
		"imKey-Fakturownia": string;
		/** Płatność z góry */
		"labelUpPayment": string;
		/** Ostatnie wpłaty */
		"labelLastPayments": string;
		/** Data rejestracji */
		"labelRegisterDate": string;
		/** Stare hasło */
		"labelOldPassword": string;
		/** Innych usług */
		"labelOtherAmount": string;
		/** Aktywny<br/>Zlecenie zostanie przekazane do windykacji po {0} dniach od zakończenia scenariusza */
		"imAutoVindicationEnabled": string;
		/** <p>Wysłaliśmy Ci e-mail z instrukcją zresetowania hasła.</p><p>Jeśli w ciągu kilku minut e-mail nie dotarł, sprawdź folder Spam lub kliknij poniżej by wysłać ponownie.</p> */
		"labelResetMessageSend": string;
		/** {0}, sprawdź swoją pocztę */
		"titleRegistrationComplete": string;
		/**  */
		"imEffectivenessOnTimeHint": string;
		/** Podaj należności */
		"titleNewDebts": string;
		/** Nazwa pliku */
		"labelFileName": string;
		/** <h1>Wkrótce odezwiemy się do Ciebie</h1> */
		"hintDebtOfferSend": string;
		/** Wielu dłużników (z pliku) */
		"tabMultipleDebtors": string;
		/** Pozostało jeszcze {0} pozycji */
		"tableItemsLeft": string;
		/** Nadaj uprawnienia */
		"titleNewPermissions": string;
		/** Eksport */
		"labelExport": string;
		/** zagraniczna */
		"labelVindicationForeign": string;
		/** Zlecono */
		"labelDebtAdded": string;
		/** Modyfikacja użytkownika w organizacji */
		"notificationUserChanged": string;
		/** Serwer jest niedostępny */
		"networkErrorServerDown": string;
		/** Edytuj dane firmy */
		"titleEditCompany": string;
		/** Podane ceny są cenami netto i należy doliczyć do nich aktualną stawkę VAT. */
		"labelPricesInfo": string;
		/** Telefon dłużnika */
		"labelDebtorPhone": string;
		/** Nazwa ubezpieczyciela */
		"labelInsurerName": string;
		/** Ostatnie zdarzenia */
		"imLabelLastMonitoringEvents": string;
		/** Uwagi */
		"labelComments": string;
		/** Raport.pdf */
		"vReportFileName": string;
		/** Lekko przeterminowana */
		"monitoringInvoiceStageSlightlyOutOfDate": string;
		/** Data zmian */
		"labelFileAdded": string;
		/** Pakiet MAIL */
		"imPacketMail": string;
		/** różne waluty dla jednego długu */
		"importInvalidDifferentCurrency": string;
		/** Kwoty główne */
		"labelDebtorMainValue": string;
		/** Monitoring wstrzymany */
		"imFilterDisabled": string;
		/** Tymczasowo zablokowany */
		"statusTemporaryBlocked": string;
		/** Zlecenie windykacji: {0} */
		"titleVindication": string;
		/** Zaakceptuj */
		"actionAgreementAccept": string;
		/** Sygnatura */
		"labelSignature": string;
		/** Uprawnienia */
		"titlePermissions": string;
		/** <b>Rozważna firma</b><br/><b>EULEO sp. z o.o. sp. k.</b><br/><br/>ul. Bociana 22<br/>31-231 Kraków */
		"contactCompanyInfo": string;
		/** Abonament */
		"accessPayment": string;
		/** Saldo */
		"labelBalance": string;
		/** Oszczędzasz 5% */
		"imSubscription3MonthInfo": string;
		/** Synchronizuj teraz */
		"imhManualImportNow": string;
		/** Zlecenie {0} zostało odrzucone */
		"notificationOrderRejectedMessage": string;
		/** Data synchronizacji */
		"labelSyncTime": string;
		/** Wysokie ryzyko */
		"labelRisky": string;
		/** Zarejestruj się! */
		"labelRegister": string;
		/** Uwagi dodatkowe */
		"labelAdditionalInfo": string;
		/** Faktury zostały usunięte z systemu. */
		"deleteInvoicesInfo": string;
		/** Kapitał */
		"labelCapital": string;
		/** 100 sms/vms */
		"imPacketSMSDetails": string;
		/** Przyjęliśmy zgłoszenie! */
		"titleServiceSend": string;
		/** Midi */
		"imSubscriptionMidi": string;
		/** NIP / Pakiet */
		"labelNIPPacket": string;
		/** Usługa */
		"labelService": string;
		/** Obiekt */
		"labelServiceTarget": string;
		/** Wgraj Plik Word lub Excel z zestawieniem należności Twojego dłużnika a nasi specjaliści wypełnią zlecenie windykacji za Ciebie. */
		"hintReceivablesFromFile": string;
		/** Faktura / nota */
		"labelInvoiceNote": string;
		/** Brak synchronizacji faktur */
		"notificationIMOutOfSync": string;
		/** Odsetki */
		"labelInterests": string;
		/** Wykryto nowy dług dla {0} (NIP: {1}): {2} */
		"notificationMonitoredDebtMessage": string;
		/** Jeśli czas monitorowani faktur minie i mimo wysłanych powiadomień klient nie ureguluje należności, system może automatycznie zlecić firmie EULEO rozpoczęcie windykacji. */
		"labelAutoVindicationInfo": string;
		/** Aktualne saldo */
		"labelActualBalance": string;
		/** Zlecono */
		"labelOrdered": string;
		/** SMS/VMS za 27 gr */
		"imSubscriptionCorporateSmsPrice": string;
		/** Kwota wpłaty */
		"vindicationPaymentAmount": string;
		/** Zatwierdzenie firmy */
		"notificationOrganizationAccepted": string;
		/** Pobierz PDF z podsumowaniem */
		"labelNewDebtSummaryPDF": string;
		/** Windykacja polubowna, sądowa */
		"nameVindicationAmicableJudicial": string;
		/** Styl komunikacji */
		"labelCommunicationStyle": string;
		/** Dane dłużnika */
		"titleDebtorData": string;
		/** Etap realizowany w momencie, gdy upłynął termin płatności dla najstarszej faktury w saldzie. Nadawcą komunikatu będzie Twoja firma, a treść komunikatów utrzymana będzie w rzeczowym, konkretnym tonie. */
		"imStageExpiredInfo": string;
		/** Importuj z pliku */
		"imhManualImportFromFile": string;
		/** Błąd wczytywania obrazka */
		"imageLoadError": string;
		/** Typ integracji */
		"labelInvoiceMonitoringIntegrationType": string;
		/** Do wyliczenia bierzemy dane z ostatnich 12 miesięcy różnica między termin płatności a oznaczeniem zapłaty za fakturę. */
		"imEffectivenessOverdueHint": string;
		/** ({0} wybrane) */
		"labelOptionsSelected": string;
		/** Brak danych do wyświetlenia */
		"dataTableEmpty": string;
		/** Napisz do nas */
		"titleContactForm": string;
		/** Nie pamiętam hasła */
		"labelNoPassword": string;
		/** brak wysokości odsetek */
		"importMissingInterestRate": string;
		/** Na podany adres została już wysłana wiadomość. Kolejną można wysłać po upływie {0} godzin. */
		"resetAlreadySend": string;
		/** Automat windykacyjny */
		"imVindicationMachine": string;
		/** złóż zlecenie z rejestracją */
		"labelRegisterWithDebt": string;
		/** Hasło jest takie same, jak poprzednie */
		"errorPasswordOld": string;
		/** Klucz API dostępny jest w <b>konfiguracji</b> konta w sekcji <b>rozszerzenia i integracje</b> w zakładce <b>API</b>. Uzyskuje się go poprzez wciśnięcie przycisku <b>Aktywuj dostęp podstawowy</b>, wówczas pojawi się tabla, z której należy skopiować klucz API o identyfikatorze <code>faktura</code>. */
		"imIntegrationInfo-iFirma": string;
		/** Dodaj użytkownika do organizacji */
		"actionAddUserToOrganization": string;
		/** by */
		"logoEuleoBy": string;
		/** Użytkownik {0} dodał {1} do monitorowanych */
		"notificationMonitoredAddedMessage": string;
		/** Numer */
		"labelNumber": string;
		/** Tak */
		"labelYes": string;
		/** NIP */
		"labelServiceNIP": string;
		/** dd.MM */
		"notificationLabelDate": string;
		/** Kup ten dług */
		"actionBuyDebt": string;
		/** Windykacja:  */
		"eventLabelVindication": string;
		/** Tymczasowo zablokowana */
		"orgStatusTempBlocked": string;
		/** Etap realizowany w momencie, gdy nie upłynął termin płatności dla najstarszej faktury w saldzie. Nadawcą komunikatu będzie Twoja firma, a treść komunikatów utrzymana będzie w delikatnym, grzecznościowym tonie. */
		"imStageBeforePaymentInfo": string;
		/** Płatność zakończona sukcesem. */
		"paymentStatusSuccess": string;
		/** Rozliczenie */
		"imsEventSettlement": string;
		/** Pokaż więcej */
		"dataTableLoadMore": string;
		/** Adres wierzyciela pierwotnego */
		"labelCreditorAddress": string;
		/** Mam kod promocyjny */
		"registerPromo": string;
		/** Automat windykacyjny */
		"labelAutoVindication": string;
		/** Zarządzaj firmą */
		"actionManageCompany": string;
		/** Rozpocznij odzyskiwanie swoich należności klikając w przycisk */
		"hintNoVindications": string;
		/** Windykacja */
		"labelDebtMode": string;
		/** Zezwól */
		"accessAllow": string;
		/** dziś */
		"agoToday": string;
		/** Polski */
		"language": string;
		/** Dłużnik */
		"labelDebtor": string;
		/** Zakup abonamentów odbywa się za pośrednictwem serwisu.  */
		"labelPaymentInfo": string;
		/** Powiadomienie o windykacji zwiększa szansę na odzyskanie należności */
		"hintNewDebtNotification": string;
		/** ID w EC */
		"labelECID": string;
		/** inFakt */
		"imIntegration-inFakt": string;
		/** Liczba windykacji */
		"labelVindicationsCount": string;
		/** Brak połączenia z serwerem. Proszę spróbować później. */
		"networkErrorNoConnectionMessage": string;
		/** Dane organizacji */
		"labelOrganizationData": string;
		/** Doświadczenie z dłużnikiem */
		"titleDebtorExperience": string;
		/** Brak powiadomień */
		"labelNoEvents": string;
		/** brakuje daty sprzedaży lub niepoprawna wartość */
		"importInvalidSellDate": string;
		/** Użytkownik {0} utworzył nową windykację {1} */
		"notificationVindicationNewMessage": string;
		/** Wiersz {0}: */
		"importInvalidRow": string;
		/** Zarządzanie organizacją */
		"labelManageOrganization": string;
		/** brakuje daty płatności lub niepoprawna wartość */
		"importInvalidPayDate": string;
		/** Wpisy z KRZ lub iMSiG.pl */
		"titleImsig": string;
		/** iFirma */
		"imIntegration-iFirma": string;
		/** Kosztami windykacji */
		"chargeCosts": string;
		/** Wstrzymana */
		"monitoringInvoiceStageDisabledHint": string;
		/** Zmień NIP */
		"titleChangeNIP": string;
		/** Przejdź do abonamentu */
		"paymentStatusGotoSubscription": string;
		/** Wszystkie długi */
		"labelAllDebts": string;
		/** W systemie pojawił się nowy plik z trudnymi długami {0} */
		"notificationDifficultDebtNewMessage": string;
		/** Inne koszty */
		"labelDebtCosts": string;
		/** Brutto: */
		"imPayGross": string;
		/** Usunięcie wpisu z listy monitorowanych */
		"notificationMonitoredRemoved": string;
		/** Rozliczony */
		"imClientPaid": string;
		/** W tym miejscu pojawią się informacje o zmianach w Twoich windykacjach. */
		"hintNoNotifications": string;
		/** Nowa */
		"orgStatusNew": string;
		/** Powyżej znajduje się token pomocy zdalnej. Przekaż go pracownikowi helpdesku, aby mógł udzielić Ci asysty. */
		"remoteHelpInfo": string;
		/** Liczba należności */
		"labelAmountOfReceivables": string;
		/** Dodaj */
		"buttonAdd": string;
		/** Hasło nie jest bezpieczne! */
		"passwordBad": string;
		/** Potwierdź! */
		"actionConfirm": string;
		/** Abonament za 3 miesiące */
		"imPay3month": string;
		/** Klucz API */
		"labelInvoiceMonitoringKey": string;
		/** Kup pakiet */
		"labelBuyPacket": string;
		/** Nie masz konta? */
		"labelDontHaveAccount": string;
		/** Poziom uprawnień */
		"labelRightsLevel": string;
		/** Przed terminem */
		"monitoringInvoiceStageBeforePayment": string;
		/** Nowe hasło */
		"labelNewPassword": string;
		/** inFakt */
		"istinFakt": string;
		/** Dodaj do obserwowanych klientów */
		"titleAddMonitored": string;
		/** Etap I - przed upływem terminu płatności */
		"imStageBeforePayment": string;
		/** przed terminem płatności */
		"imLabelBeforeDateOfPayment": string;
		/** Kwota główna */
		"labelDebtMainValue": string;
		/** Dłużnik figuruje w bazie */
		"infoDebtor": string;
		/** Najnowsze windykacje */
		"titleNewestVindications": string;
		/** Wybierz z dysku */
		"actionAddFile": string;
		/** Adres */
		"labelAddress": string;
		/** Wyrażam zgodę na otrzymywanie od administratora telefonicznie, e-mailem lub SMS-em informacji handlowych */
		"registerTerm2": string;
		/** Akceptuję regulamin i politykę prywatności oraz tym samym wyrażam zgodę na przetwarzanie przez administratora moich danych osobowych zgodnie z RODO * */
		"registerTerm1": string;
		/** Windykacja sądowa */
		"nameVindicationJudicial": string;
		/** Przyjmij */
		"actionAccept": string;
		/** SMS */
		"notificationSMS": string;
		/** <h1>Należności możesz dodać z pliku CSV lub XLSX o odpowiedniej strukturze.</h1><p>Aby to zrobić pobierz, a następnie uzupełnij szablon pliku <a href="/resources/templates/naleznosci.xlsx">naleznosci.xlsx</a></p> */
		"hintAddFromCSV": string;
		/** W windykacji */
		"monitoringStageVindication": string;
		/** Szablony wyszukiwania pozwalają na szybkie, ponowne użycie zestawu filtrów które wybrano dla aktualnego wyniku wyszukiwań. Szablony te nie zapisują wyników. */
		"hintFilterSave": string;
		/** Zlecenie windykacji */
		"vReportNone": string;
		/** Inne wierzytelności tego dłużnika na sprzedaż */
		"labelOtherDebtsForSale": string;
		/** Monitoruj */
		"imInvoiceResume": string;
		/** Brak danych do analizy skuteczności */
		"imEffectivenessNoData": string;
		/** Jeszcze kilka informacji o Tobie */
		"titleRegisterWithVindication": string;
		/** Dodatkowe pakiety nie są dostępne dla konta Free oraz Corporation. */
		"imPacketNotAvailable": string;
		/** Skuteczność monitoringu */
		"imLabelEffectivenessOfMonitoring": string;
		/** Nie znam NIP/PESEL dłużnika */
		"labelNoDebtorNIP": string;
		/** Link SMS otwarty */
		"imSMSLinkViewed": string;
		/** Dla podanego numeru NIP jest już zarejestrowane konto */
		"errorOrganizationExists": string;
		/** Błędne hasło! */
		"invalidPassword": string;
		/** Scenariusz monitoringu */
		"labelMonitoringScenario": string;
		/** Szukaj po nazwie pliku */
		"hintFileSearch": string;
		/** Błędny kod. Spróbuj ponownie lub wygeneruj nowy obrazek. */
		"captchaError": string;
		/** Zmień hasło */
		"actionChangePassword": string;
		/** < Wróć */
		"buttonReturn": string;
		/** Powiadom dłużnika o wszczęciu windykacji */
		"labelNewDebtNotificationCheckbox": string;
		/** Moje szablony wyszukiwania */
		"labelMyTemplates": string;
		/** lub<br/>przeciągnij i upuść w tym miejscu */
		"hintDragAndDropFile": string;
		/** Data płatności */
		"labelPayDate": string;
		/** Zatrzymana */
		"imStatusDisabled": string;
		/** Data ostatniej synchronizacji */
		"imLabelLastSyncDate": string;
		/** Ograniczone prawo dostępu */
		"titleNoCompanies": string;
		/** Pakiet */
		"labelPacket": string;
		/** wFirma */
		"istwFirma": string;
		/** Struktura długu */
		"titleDebtStructure": string;
		/** Klucz API generuje się w ustawieniach konta, w sekcji <b>API</b>. Klucz musi mieć uprawnia do odczytu faktur (<code>api:invoices:read</code>). */
		"imIntegrationInfo-inFakt": string;
		/** Statystyki użytkownika */
		"titleUserStats": string;
		/** Pozostało */
		"labelDebtLeft": string;
		/** Adres (ulica, budynek, lokal) */
		"labelFullAddress": string;
		/** wiele faktur o tym samym numerze dla jednego kontrahenta */
		"importInvalidMultipleInvoices": string;
		/** Użytkownik {0} dodał użytkownika {1} do firmy */
		"notificationUserAddedMessage": string;
		/** NIP dłużnika */
		"labelDebtorNip": string;
		/** Przekroczone 100% dpd */
		"imStageExceedingScenarioInfo": string;
		/** Dodaj z pliku CSV lub XLSX */
		"actionAddFromCSV": string;
		/** Data i nr sprawy */
		"labelDateAndNumber": string;
		/** Windykacja sądowa */
		"labelAutoVindicationJudicial": string;
		/** Nowy */
		"labelNew": string;
		/** Rozliczona */
		"monitoringInvoiceStageSettled": string;
		/** Rodzaj cennika */
		"labelPricesType": string;
		/** Odrzucony */
		"statusRejected": string;
		/** Klucz API */
		"labelApiKey": string;
		/** opcja dokupienia pakietu sms/vms i email */
		"imSubscriptionAdd": string;
		/** Nowa płatność w windykacji */
		"notificationVindicationPayment": string;
		/** Usuń zaznaczone */
		"actionDeleteSelected": string;
		/** krajowa */
		"labelVindicationDomestic": string;
		/** Hasło zostało zmienione */
		"titleResetPasswordChanged": string;
		/** Etap IV - po upływie terminu płatności, gdy należności są mocno przeterminowane */
		"imStageHeavilyOverdue": string;
		/** Dokument o takim numerze już jest zarejestrowany w systemie. */
		"hintDocumentExists": string;
		/** w  */
		"eventLabelIn": string;
		/** Płatność z dołu */
		"labelCorporatePayment": string;
		/** Błędny login lub hasło! */
		"invalidLoginOrPassword": string;
		/** <h1>Zaimportowano długi z pliku</h1> */
		"importHint": string;
		/** Brak dostępu do usługi w aktualnym pakiecie. */
		"serviceNotAvailableInPacket": string;
		/** Zlecenie windykacji polubownej */
		"vReportAmicable": string;
		/** Odsetkami */
		"chargeInterest": string;
		/** Błąd dodawania pliku */
		"uploadErrorTitle": string;
		/** Powód odrzucenia */
		"labelRejectionReason": string;
		/** Wartość długu */
		"labelDebtValue": string;
		/** Zakończ */
		"actionFinish": string;
		/** By zwiększyć szanse na odzyskanie należności podaj informacje pomocne w dotarciu do dłużnika. */
		"labelNewDebtSummary": string;
		/** Ilość postępowań */
		"labelDebtorProcedures": string;
		/** Dostępne w pakiecie */
		"imSubscriptionAvailable": string;
		/** Ostatnie logowanie do organizacji */
		"labelLastOrganizationLogin": string;
		/** Błędny kod */
		"registerInvalidActivationCode": string;
		/** niepoprawna nazwa kraju */
		"importInvalidCountry": string;
		/** Aktualny */
		"labelSummaryActual": string;
		/** Nie dodano żadnej firmy do obserwowanych klientów */
		"infoMonitoredAddedNone": string;
		/** Monitoring wyłączony */
		"imClientDisabled": string;
		/** Przejdź do zgłoszenia */
		"actionGoToVindication": string;
		/** Wysłane przypomnienia */
		"tabSendNotifications": string;
		/** Dodaj użytkownika */
		"titleAddUser": string;
		/** Przekroczono limit monitorowanych */
		"errorLimitExceeded": string;
		/** W windykacji */
		"monitoringClientVindication": string;
		/** Tytuł wiadomości */
		"labelMessageTitle": string;
		/** Zawsze za darmo! */
		"labelAlwaysFree": string;
		/** Import z pliku */
		"istImport": string;
		/** Hasło musi mieć przynajmniej 8 znaków, zawierać małą i dużą literę, znak specjalny oraz cyfrę. */
		"validatePasswordStrength": string;
		/** Numer telefonu o podwyższonej opłacie */
		"imExtraPhoneNumber": string;
		/** <h1>Zlecenia możesz dodać z pliku CSV lub XLSX o odpowiedniej strukturze.</h1><p>Aby to zrobić pobierz, a następnie uzupełnij szablon pliku <a href="/resources/templates/windykacje.xlsx" target="_blank">windykacje.xlsx</a>.</p> */
		"hintNewDebtsUpload": string;
		/** {0} z 250 maili, {1} z 250 SMS/VMS */
		"imSubscriptionMaxiUsage": string;
		/** Wiadomość */
		"labelMessage": string;
		/** Wysokość odsetek */
		"labelChangeInterestRate": string;
		/** Email */
		"notificationEmail": string;
		/** Zleć windykację dłużnika */
		"titleNewDebt": string;
		/** Informacja o przetwarzaniu danych osobowych */
		"linkRODO": string;
		/** Płatność w trakcie przetwarzania. Sprawdź w historii transakcji jej aktualny status. */
		"paymentStatusProcessing": string;
		/** Windykacji */
		"labelVindicationAmount": string;
		/** Zamknij */
		"actionClose": string;
		/** Nowa płatność w windykacji {0} */
		"notificationVindicationPaymentMessage": string;
		/** Niepowodzenie */
		"issFailure": string;
		/** Sprawdź czy jest dłużnikiem */
		"titleCheckIfDebtor": string;
		/** Twoje logo */
		"labelYourLogo": string;
		/** dziś */
		"labelToday": string;
		/** SMS/VMS */
		"channelSMS": string;
		/** Integracja z {0} */
		"imIntegrationType": string;
		/** Opis */
		"labelDescription": string;
		/** Nie pamiętam hasła */
		"titlePasswordReset": string;
		/** iFirma */
		"istiFirma": string;
		/** Podgląd */
		"labelPreview": string;
		/** Data przyjęcia */
		"labelRegisteredDate": string;
		/** Zaakceptuj i zaloguj się */
		"actionAcceptTerms": string;
		/** 200 maili */
		"imPacketMailDetails": string;
		/** Data Integrator */
		"istDataIntegrator": string;
		/** {0}: Utworzenie nowego zlecenia */
		"notificationOrderNew": string;
		/** Serwer jest obecnie niedostępny. Proszę spróbować później. */
		"networkErrorServerDownMessage": string;
		/** Nieopłacona */
		"imStatusUnpaid": string;
		/** Kontrahent */
		"labelContractor": string;
		/** W realizacji */
		"statusInProgress": string;
		/** Etap III - po upływie terminu płatności, gdy należności są przeterminowane */
		"imStageExpired": string;
		/** Określ scenariusz monitoringu */
		"labelEditTitle": string;
		/** Typ zdarzenia */
		"labelEventType": string;
		/** Dodaj organizację */
		"titleAddOrganization": string;
		/** Dezaktywuj */
		"actionDisableApiKey": string;
		/** Aby uzyskać klucz <code>Access key</code> oraz <code>Secret key</code>, należy zalogować się na swoje konto wFirma, przejść do ekranu <b>Ustawienia</b> i wybrać na nim w części <b>Bezpieczeństwo</b> pozycję <b>Aplikacje</b>. Spowoduje to wyświetlenie okna, w którym w części <b>Klucze API</b> jest możliwość wygenerowania (dodania) nowego klucza API. Wykonuje się to wybierając akcję <b>Dodaj</b> i wprowadzając nazwę aplikacji w oknie, które się pojawi <code>RozważnaFirma</code>. Po zatwierdzeniu wygenerowany zostanie klucz <code>Access key</code> oraz <code>Secret key</code>, które należy wprowadzić w powyższym formularzu. Pole <code>Company Id</code> jest opcjonalne i należy je podać, gdy korzysta się z wielu firm w ramach jednego konta wFirma. */
		"imIntegrationInfo-wFirma": string;
		/** wysyłaj przypomnienie o zapłacie na: */
		"labelStepSetting": string;
		/** Znaleźliśmy u Ciebie minimum 1 windykację, którą prowadzisz u tego dłużnika. */
		"labelSelectDebtInfo": string;
		/** <b>Uwaga!</b><br/>Usługa realizowana będzie w oparciu o <a href="{0}" target="_blank">cennik globalny</a>. */
		"hintNewDebtGlobalPrices": string;
		/** Mocno przeterminowana */
		"monitoringInvoiceStageHeavilyOverdue": string;
		/** Identyfikator firmy (opcjonalnie) */
		"imKey3-wFirma": string;
		/** Importuj faktury */
		"labelImportInvoices": string;
		/** Odrzuć windykację */
		"imVindicationCancel": string;
		/** Analiza sald i dopasowanie scenariusza odbywa się z uwzględnieniem wartości bezwzględnych (nie jest brana wartość kursowa walut, w przypadku sprzedaży w wielu walutach). */
		"imScenarioHint": string;
		/** Etap II - po upływie terminu płatności, gdy należności są lekko przeterminowane */
		"imStageSlightlyOutOfDate": string;
		/** Postępy możesz obserwować na stronie windykacji o numerze: {0} */
		"labelNewDebtNumber": string;
		/** Obserwowani klienci */
		"titleMonitored": string;
		/** {0}: Zakończenie zlecenia */
		"notificationOrderDone": string;
		/** Usunięcie szablonu nie będzie miało wpływu na dane - stracisz wyłącznie możliwość wybrania tego zestawu filtrów. */
		"hintFilterDelete": string;
		/** Windykacje w toku */
		"labelVindicationInProgress": string;
		/** Aktywuj konto */
		"titleActivateAccount": string;
		/** Zarejestrowaliśmy zlecenie windykacji */
		"titleNewDebtSummary": string;
		/** Dostęp do spraw */
		"apiKeyVindication": string;
		/** Abonament */
		"menuInvoiceMonitoringSubscription": string;
		/** Poprzedni obraz */
		"labelPrevImage": string;
		/** Złożenie oferty kupna długu */
		"notificationDebtExchangeBuy": string;
		/** Integracja z: {0} */
		"imIntegrationWith": string;
		/** Etap realizowany w momencie, gdy upłynął termin płatności dla najstarszej faktury w saldzie. Nadawcą komunikatu będzie Rozważna Firma, a treść komunikatów utrzymana będzie w roszczeniowym, ostrym tonie. */
		"imStageHeavilyOverdueInfo": string;
		/** 250 maili, 250 SMS/VMS */
		"imSubscriptionMaxiCount": string;
		/** Błąd dostarczenia e-maila */
		"imEmailDeliveryError": string;
		/** Przyjęliśmy ofertę */
		"titleDebtOfferSend": string;
		/** Scenariusz */
		"labelScenario": string;
		/** Zmień scenariusz */
		"buttonChangeScenario": string;
		/** Uwaga! Wybrane akcje są nie odwracalne, wybranie ich spowoduje bezpowrotną utratę historii monitoringu, statystyk dla klientów. */
		"labelDeleteInfo": string;
		/** Posiada dostęp do */
		"labelAccessInfo": string;
		/** Brak przypisanego scenariusza */
		"monitoringStageNoScenario": string;
		/** Zmień ustawienia */
		"imIntegrationChange": string;
		/** Ostatnie logowanie */
		"labelLastLogged": string;
		/** Pomiń w monitoringu kontrahentów z saldem poniżej wskazanej wartości */
		"labelSkipWithBalanceLess": string;
		/** Czas zdarzenia */
		"labelEventTime": string;
		/** represyjny */
		"issHeavilyOverdue": string;
		/** Wierzytelność jest ubezpieczona */
		"labelDebtInsured": string;
		/** Importuj */
		"buttonImport": string;
		/** Do godziny */
		"labelNotificationTo": string;
		/** Rekomendowana liczba klientów* = {0} */
		"labelRecommendedContractors": string;
		/** Dane */
		"labelMainData": string;
		/** Najnowszy dług */
		"labelNewestDebt": string;
		/** Nie ma już dostępnych SMSów w pakiecie */
		"notificationIMOutOfSMS": string;
		/** Błędy kod */
		"resetInvalidCode": string;
		/** Dane zapytania:  */
		"networkErrorPayload": string;
		/** Dezaktywacja klucza */
		"apiKeyDisableTitle": string;
		/** Potrzebujesz pomocy? */
		"labelNeedHelp": string;
		/** Finansowe */
		"tabFinances": string;
		/** Edycja */
		"accessEdit": string;
		/** Twój e-mail */
		"labelYouEmail": string;
		/** Dodaj filtr */
		"actionAddFilter": string;
		/** Im więcej dodasz informacji tym szybciej może udać się odzyskać Twoje należności. Mogą to być np.: e-mail i telefon, adres zamieszkania, nazwa i adres miejsca pracy, imię/nazwisko/miejsce pracy małżonka. pełnione funkcje społeczne (działalność w organizacjach, stowarzyszeniach), linki do mediów społecznościowych (konta na FB, numery komunikatorów etc.) */
		"hintNewDebtSummary": string;
		/** Cena */
		"labelPrice": string;
		/** stanowczy */
		"issExpired": string;
		/** Zapisz i zaloguj */
		"actionChangeAndLogin": string;
		/** E-mail dłużnika */
		"labelDebtorEmail": string;
		/** Faktury */
		"menuInvoiceMonitoringInvoices": string;
		/** Zabronione */
		"accessDeny": string;
		/** W powiadomieniach */
		"labelNotificationsInNotifications": string;
		/** Kwotowa suma długów */
		"labelAllDebtsSum": string;
		/** niepoprawny typ dokumentu (nieodnaleziony w słowniku) */
		"importInvalidDocumentType": string;
		/** Nowy plik {1} w windykacji {0} */
		"notificationVindicationFileMessage": string;
		/** Wysyłaj tylko w dni robocze */
		"labelNotificationWorkingDaysOnly": string;
		/** Ustawienia powiadomień */
		"titleNotificationSettings": string;
		/** Usuń wszystkie dane monitoringu */
		"actionDeleteAll": string;
		/** Zapisz */
		"buttonSave": string;
		/** Data publikacji na giełdzie */
		"labelExchangePublished": string;
		/** Możesz ograniczyć dostęp do API tylko z wybranych adresów IP.<br/>Wprowadź adresy IP lub zakresy adresów IP (np. <code>192.168.1.5-192.168.1.10</code>), z których będą wykonywane zapytania do API oddzielone przecinkami (<code>,</code>).<br/>Jeżeli nie wprowadzisz żadnego adresu IP, dostęp do API będzie możliwy z dowolnego adresu IP. */
		"labelIPFilterHint": string;
		/** Utwórz konto */
		"titleRegister": string;
		/** Cena sprzedaży */
		"labelDebtSellPrice": string;
		/** Zgody */
		"labelAgreements": string;
		/** Utworzenie nowego klucza */
		"apiKeyRegenerateTitle": string;
		/** Zapisz zmiany */
		"actionSaveChanges": string;
		/** Utwórz */
		"imNewVindicationCreate": string;
		/** Następny obraz */
		"labelNextImage": string;
		/** Dodano 1 firmę do obserwowanych klientów */
		"infoMonitoredAddedOne": string;
		/** Brak powiadomień */
		"labelNoNotifications": string;
		/** Włącz automatyczne zlecanie windykacji (O każdym złożonym zleceniu zostaniesz powiadomiony drogą mailową) */
		"labelAutoVindicationSwitch": string;
		/** Nie wybrano żadnej faktury do windykacji! */
		"imNewErrorSelectAny": string;
		/** Dodaj firmę */
		"actionAddCompany": string;
		/** Klucze API */
		"labelApiKeys": string;
		/** Niepoprawny numer e-mail */
		"imInvalidEmail": string;
		/** Błąd przetwarzania */
		"networkErrorOther": string;
		/** Zapisz */
		"actionSave": string;
		/** {0} z 100 maili, {1} z 100 SMS/VMS */
		"imSubscriptionMidiUsage": string;
		/** Czy chcesz usunąć ten szablon wyszukiwania? */
		"titleFilterDelete": string;
		/** Konto w trakcie weryfikacji, ograniczone funkcjonalności, wkrótce zostanie aktywowane. */
		"hintNoCompanies": string;
		/** Brak aktywności w sprawie */
		"labelActivityFilter": string;
		/** Saldo */
		"labelSummaryBalance": string;
		/** Waluta */
		"labelDebtCurrency": string;
		/** Dodaj użytkownika (e-mail) */
		"hintAddUser": string;
		/** Niepoprawne wywołanie HTTP */
		"networkErrorInvalidRequest": string;
		/** Dodaj użytkownika */
		"actionAddUser": string;
		/** Corporate */
		"imSubscriptionCorporate": string;
		/** Stan monitoringu */
		"labelMonitoringState": string;
		/** Utworzenie nowej windykacji */
		"notificationVindicationNew": string;
		/** Windykacje oczekujące */
		"menuInvoiceMonitoringVindications": string;
		/** Powiadom dłużnika o wszczęciu windykacji */
		"labelNewDebtNotification": string;
		/** Dodaj więcej */
		"actionAddMore": string;
		/** Zmień plik */
		"buttonUploadChange": string;
		/** <b>Uwaga!</b><br/>Usługa realizowana będzie w oparciu o <a href="{0}" target="_blank">obowiązujący cennik</a>. */
		"hintNewDebtPrices": string;
		/** % wartości długu */
		"labelDebtPercentage": string;
		/** Tytuł */
		"labelTitle": string;
		/** Rozliczany w dniu {0} miesiąca */
		"imSubscriptionPeriodDay": string;
		/** 2 miesiące gratis! */
		"imSubscription12MonthInfo": string;
		/** Im dłuższy abonament kupujesz, tym więcej oszczędzasz. */
		"imSubscription1MonthInfo": string;
		/** Zaloguj się */
		"titleLogin": string;
		/** Data Integrator */
		"imIntegration-DataIntegrator": string;
		/** Zleć usługę */
		"actionAddService": string;
		/** Pakietu nie można przedłużyć. Przedłużenie jest dostępne w ostatnim miesiącu trwania pakietu. */
		"imSubscriptionNoExtend": string;
		/** E-mail za 7 gr */
		"imSubscriptionCorporateEmailPrice": string;
		/** Polityka prywatności */
		"linkPrivacyPolicy": string;
		/** Zamknięte */
		"stageDone": string;
		/** Zapisz jako szablon */
		"actionSaveTemplate": string;
		/** Jeśli wykorzystałeś pulę wiadomości e-mail i SMS/VMS przypadającą na dany miesiąc w Twoim abonamencie i potrzebujesz wysłać dodatkowe monity, dokup pakiet wiadomości. WAŻNE: Opcja dostępna tylko dla abonamentów: Mini, Midi i Maxi. */
		"labelInvoiceMonitoringPacketsInfo": string;
		/** Historia windykacji */
		"tabHistory": string;
		/** Pakiet SMS + MAIL */
		"imPacketSMSMail": string;
		/** brakuje numeru należności */
		"importInvalidNumber": string;
		/** Nazwa usługi */
		"labelServiceName": string;
		/** Ten kontrahenta płaci zobowiązania terminowo. */
		"imEffectivenessOnTime": string;
		/** Mocno przeterminowane */
		"monitoringStageHeavilyOverdue": string;
		/** Windykacyjnych */
		"labelDebtorVindications": string;
		/** Podsumowanie */
		"titleSummary": string;
		/** Zakres zlecenia */
		"labelDebtType": string;
		/** Pobierz */
		"actionDownload": string;
		/** Dodano {0} firm do obserwowanych klientów */
		"infoMonitoredAddedMany": string;
		/** Tymczasowo zablokowani */
		"labelUsersTempBlocked": string;
		/** Czy jesteś pewien, że chcesz to usunąć? Operacja jest nieodwracalna. */
		"deleteConfirm": string;
		/** Wysłany na */
		"labelSendTo": string;
		/** Lekko przeterminowane */
		"monitoringStageSlightlyOutOfDate": string;
		/** Twoja firma {0} została dodana do konta! */
		"hintCompanyAdded": string;
		/** Po terminie */
		"labelOverdue": string;
		/** Pozostałe Twoje zlecenia na tego dłużnika */
		"titleOtherDebtorVindications": string;
		/** E-mail/login */
		"labelEmailLogin": string;
		/**  */
		"imSubscriptionExtraInfo": string;
		/** SMS */
		"labelSMS": string;
		/** Wysłane powiadomienia */
		"menuInvoiceMonitoringNotifications": string;
		/** Brak danych */
		"tableEmpty": string;
		/** Wersja: {0} */
		"labelVersionLang": string;
		/** Kontrahent {0} przekroczył 100% stanu monitoringu i został przekazany do windykacji. Zostało utworzone {1} windykacji. */
		"notificationIMClientVindicationMultiple": string;
		/** Telefon do kontaktu w związku z powiadomieniami */
		"labelNotificationPhone": string;
		/** grzeczny */
		"issBeforePayment": string;
		/** Usuń plik */
		"buttonFileRemove": string;
		/** Dowolna liczba klientów */
		"labelCorporateContractors": string;
		/** <b>{0}</b>, wprowadziliśmy zmiany w naszym regulaminie, polityce prywatności i zgodach marketingowych. Zaakceptuj wymagane zgody przed zalogowaniem. */
		"termsInfo": string;
		/** Wierzyciel pierwotny */
		"labelPrimaryCreditor": string;
		/** Konto nie zostało aktywowane! */
		"accountNotActivated": string;
		/** Pokaż tylko dłużników */
		"labelShowOnlyDebtors": string;
		/** Liczba użytkowników */
		"labelUsersCount": string;
		/** Data zlecenia */
		"labelOrderDate": string;
		/** Możesz dodać jeszcze {0} firm.<br/>Skontaktuj się z nami, aby zwiększyć ilość obserwowanych. */
		"hintAddMonitored": string;
		/** lub<br/>przeciągnij i upuść w tym miejscu */
		"hintUpload": string;
		/** Numer telefonu nie jest z Polski */
		"imNotPolandPhoneNumber": string;
		/** Obserwowani klienci */
		"apiKeyMonitoring": string;
		/** Pliku '{0}' nie udało się przesłać, ponieważ został przekroczony limit rozmiaru ({1}). */
		"uploadSizeError": string;
		/** data płatności nie może być wcześniejsza niż data sprzedaży */
		"importInvalidSellPayDate": string;
		/** Ustaw scenariusz */
		"changeScenarioTitle": string;
		/** Przed wysłaniem sprawdź poprawność poniższych danych */
		"hintCheckUploadedData": string;
		/** Nowy plik w windykacji */
		"notificationVindicationFile": string;
		/** Ubezpieczone w {0} */
		"labelInsuredIn": string;
		/** Wstrzymaj monitoring po wskazanej liczbie dni bez synchronizacji */
		"labelStopMonitoringWithSynchronization": string;
		/** Płatność */
		"imsEventPayment": string;
		/** Czas wystąpienia błędu:  */
		"networkErrorTime": string;
		/** Brak powiadomień */
		"titleNoNotifications": string;
		/** Kod promocyjny */
		"labelPromoCode": string;
		/** Odsetki */
		"labelDebtorInterests": string;
		/** Poznaj Rozważną Firmę */
		"authInfo": string;
		/** ID */
		"labelID": string;
		/** Rozwiń kroku scenariusza */
		"imScenarioStepsShow": string;
		/** Zleć */
		"actionOrder": string;
		/** Odzyskano */
		"labelRecovered": string;
		/** {0} ekstra emaili, {1} ekstra SMS/VMS */
		"imSubscriptionExtraAvailability": string;
		/** Nazwa firmy */
		"labelCompanyName": string;
		/** Nazwa dłużnika */
		"labelDebtorName": string;
		/** Ustaw scenariusz */
		"buttonSelectScenario": string;
		/** Brak opcji */
		"comboboxNoOptions": string;
		/** Uzupełnij dane firmy */
		"titleCompanyData": string;
		/** SMS/VMS */
		"labelStepSMS": string;
		/** ważny do {0} */
		"infoValidTo": string;
		/** Sygn. KM */
		"labelBailiffRefNumber": string;
		/** Dezaktywowanie klucza jest operacją nieodwracalną. Systemy, które używają tego klucza, utracą dostęp do API. */
		"apiKeyDisableInfo": string;
		/** Nowa wierzytelność dla windykacji */
		"notificationVindicationReceivable": string;
		/** Na tym etapie od */
		"labelLastChanged": string;
		/** Najnowsze zlecenia */
		"titleNewestOrders": string;
		/** Nie wszystkie faktury zostały zlecone */
		"imVindicationVindicationWithInvoicesFilter": string;
		/** Pojawił się nowy wpis w Monitorze Sądowym {0} (NIP: {1}) */
		"notificationMonitoredImsigMessage": string;
		/** Logo będzie załączone do wiadomości e-mail wysłanych z systemu oraz na stronie podglądu. Maksymalny rozmiar logo to 1 MB, wymiary loga nie mogą przekraczać 320x200. Jeżeli zostanie załączony grafika o większych wymiarach, to zostania automatycznie pomniejszona. */
		"labelYourLogoHint": string;
		/** Oczekujący na przyjęcie */
		"statusGrace": string;
		/** Kod pocztowy */
		"labelPostalCode": string;
		/** Pliki */
		"tabFiles": string;
		/** Kliknij aby zmienić kolejność sortowania */
		"actionClickToSort": string;
		/** Zablokowana */
		"orgStatusBlocked": string;
		/** Wybierz jedną z oferowanych przez nas usług poniżej */
		"hintNoService": string;
		/** Monitoring */
		"menuInvoiceMonitoringDesktop": string;
		/** Pozostaw */
		"actionLeave": string;
		/** Administracyjna zmiana pakietu ekstra */
		"imsEventAdminExtra": string;
		/** Zgoda */
		"labelAgreement": string;
		/** Użytkownik {0} usunął {1} z monitorowanych */
		"notificationMonitoredRemovedMessage": string;
		/** Zaloguj się */
		"actionLogin": string;
		/** Musimy poprosić Cię jeszcze o uzupełnienie danych tej firmy */
		"hintAddCompany": string;
		/** Koszty */
		"labelCosts": string;
		/** Numer sprawy */
		"labelCaseNumber": string;
		/** Pozostałe ustawienia */
		"imOtherSettings": string;
		/** Typ zlecenia */
		"labelOrderType": string;
		/** Aktualne saldo */
		"labelCurrentBalance": string;
		/** Kup */
		"actionBuy": string;
		/** Wykonaj */
		"buttonExecute": string;
		/** Ustawienia monitoringu */
		"menuInvoiceMonitoringSettings": string;
		/** Status */
		"labelStatus": string;
		/** Po zalogowaniu pokaż */
		"labelStartScreen": string;
		/** Dodaj do windykacji: {0} */
		"actionAddToDebt": string;
		/** Wgraj zestawienie należności w pliku */
		"labelUploadDebts": string;
		/** Wstrzymaj wysyłkę powiadomień */
		"imClientPause": string;
		/** Fakturownia */
		"istFakturownia": string;
		/** podział wartości faktur wg. przeterminowania */
		"imLabelInvoicesByStages": string;
		/** Switch to English */
		"langChange": string;
		/** Proponowana cena */
		"labelDebtBuyPrice": string;
		/** Długi ryzykowne */
		"labelRiskDebts": string;
		/** Rodzaj należności */
		"labelDocumentType": string;
		/** Firmy */
		"tabCompanies": string;
		/** Abonament za 1 miesiąc */
		"imPay1month": string;
		/** dodaj wiele z pliku CSV lub XLSX */
		"actionFileAddMonitored": string;
		/** Przekroczone 100% dpd */
		"imStageExceedingScenario": string;
		/** Konto tymczasowo zablokowane! */
		"accountTemporaryBlocked": string;
		/** Ten kontrahent zazwyczaj płaci z opóźnieniem {0} dni. */
		"imEffectivenessOverdue": string;
		/** ...wybierz... */
		"labelSelectOption": string;
		/** Dodaj filtr */
		"labelAddFilter": string;
		/** Switch to */
		"langChangePrefix": string;
		/** lub */
		"labelOr": string;
		/** Wybierz państwo... */
		"labelCreditorCountryPlaceholder": string;
		/** Dane należności */
		"vReportReceivablesSection": string;
		/** Kontrahent zagrożony */
		"monitoringStageExceedingScenario": string;
		/** Płatność niezrealizowana */
		"notificationIMPaymentFailed": string;
		/** Szukaj */
		"titleSearch": string;
		/** Przypisz aktualny scenariusz do wszystkich kontrahentów */
		"actionApplySettingsForce": string;
		/** Zlecenia do akceptacji */
		"titleOrdersToAccept": string;
		/** Eksportuj wyniki */
		"actionExportResults": string;
		/** Sprawdź długi */
		"actionCheckDebtor": string;
		/** wFirma */
		"imIntegration-wFirma": string;
		/** Kontrahent {0} przekroczył 100% stanu monitoringu i został przekazany do windykacji. Masz {1} dni na anulowanie zlecenia. */
		"notificationIMClientVindicationGrace": string;
		/** Długi tego dłużnika wobec innych wierzycieli */
		"titleOtherDebts": string;
		/** Zaznacz wszystkie */
		"labelSelectAll": string;
		/** Twoje windykacje */
		"titleYourVindications": string;
		/** W tym miejscu będą pokazywać się najnowsze wpłaty z odzyskiwanych przez Ciebie należności.<br/>Rozpocznij tworząc <a href="{0}">nową windykację</a>. */
		"hintNoPayments": string;
		/** Postępy możesz obserwować na stronie windykacji o numerze:  */
		"labelNewVindicationId": string;
		/** Nie */
		"labelNo": string;
		/** Nowa windykacja */
		"actionNewVindication": string;
		/** Konto dla podanego adresu e-mail już istnieje */
		"errorUserAlreadyExists": string;
		/** Ostatnie ogłoszenie z dnia: {0} */
		"labelImsigLast": string;
		/** Trudne długi */
		"titleDifficultDebts": string;
		/** Koszty */
		"labelDebtorCosts": string;
		/** Parametry zlecenia */
		"titleNewDebtsParams": string;
		/** Brak zarejestrowanych długów dla podanego numeru NIP */
		"infoNotDebtor": string;
		/** Brak */
		"rightsNone": string;
		/** Pierwotny */
		"labelSummaryInitial": string;
		/** Wpływ na saldo */
		"labelCostAffectsBalance": string;
	},
	links: {
		/** https://www.youtube.com/channel/UCcb-ZS9zB3EeS5yLzS8BNoQ/about */
		"youtube": string;
		/** www.facebook.com/RozwaznaFirma */
		"facebookLabel": string;
		/** https://ankiety.pl/12344 */
		"rateUs": string;
		/** +48 12 666 0 667 */
		"phoneLabel": string;
		/** Monitoring faktur */
		"invoiceMonitoringLabel": string;
		/** https://rozwaznafirma.pl/jak-to-dziala/odzyskiwanie-dlugow/ */
		"knowledgeBase": string;
		/** https://faq2.rozwaznafirma.pl/knowledge-base/ */
		"faq": string;
		/** FAQ */
		"faqLabel": string;
		/** https://rozwaznafirma.pl/jak-to-dziala/monitoring-faktur/ */
		"invoiceMonitoring": string;
		/** bok@rozwaznafirma.pl */
		"email": string;
		/** https://euleo.pl/ */
		"euleo": string;
		/** Rozważna Firma<br/>ul. Bociana 22, 31-231 Kraków */
		"address": string;
		/** www.youtube.com/channel/UCcb-ZS9zB3EeS5yLzS8BNoQ/about */
		"youtubeLabel": string;
		/** https://rozwaznafirma.pl/jak-to-dziala/dolacz-do-rozwaznych/ */
		"debtExchange": string;
		/** Oceń naszą pracę */
		"rateUsLabel": string;
		/** https://www.facebook.com/RozwaznaFirma */
		"facebook": string;
		/** https://www.linkedin.com/company/rozwa%C5%BCna-firma/ */
		"linkedIn": string;
		/** bok@rozwaznafirma.pl */
		"imCorporate": string;
		/** +48126660667 */
		"phone": string;
		/** EULEO */
		"euleoLabel": string;
		/** Jak korzystać z giełdy długów? */
		"debtExchangeLabel": string;
		/** Baza wiedzy */
		"knowledgeBaseLabel": string;
		/** bok@rozwaznafirma.pl */
		"emailLabel": string;
		/** www.linkedin.com/company/rozwa%C5%BCna-firma/ */
		"linkedInLabel": string;
	},
};

export function isLangKey(msg: string): string|null {
	if(typeof(msg)!=='string') return false
	if(msg.startsWith('validation.')) return 'validation';
	if(msg.startsWith('gui.')) return 'gui';
	if(msg.startsWith('links.')) return 'links';
	return null;
}
export function emptyLangString(): LangString {
	return {pl: '', en: '', };
}
