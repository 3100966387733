//@flow
import React from 'react';
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {useMsgs} from "./Language";

/**
 * Komponent do obsługi klucza API.
 */
const ApiKeyComponent = ({ value, label, onDisable, onGenerate }: {
    label?: React$Node;
    /** Aktualny klucz */
    value: string|null;
    /** Funkcja wywoływana w przypadku chęci wyłączenia klucza */
    onDisable?: () => void;
    /** Funkcja wywoływana w przypadku chęci utworzenia/wygenerowania nowego klucza API */
    onGenerate?: () => void;
}) => {
    const msgs=useMsgs();
    return <InputGroup>
        {label!==false && <InputGroup.Prepend>{label && msgs.gui.labelApiKey}</InputGroup.Prepend>}
        <FormControl value={value || ""} readOnly className="static"/>
        <InputGroup.Append>
            <Button variant="outline-secondary" disabled={!value} onClick={() => {
                if(value) navigator.clipboard.writeText(value);
            }}>{msgs.gui.actionCopy}</Button>
            {onDisable && <Button variant="outline-secondary" onClick={onDisable} disabled={!value}>{msgs.gui.actionDisableApiKey}</Button>}
            {onGenerate && <Button variant={value?"outline-secondary":"outline-secondary"} onClick={onGenerate}>{value?msgs.gui.actionRegenerateApiKey:msgs.gui.actionCreateApiKey}</Button>}
        </InputGroup.Append>
    </InputGroup>
}
export default ApiKeyComponent;

